<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
    <CardComponent
      :title="cardTitle"
      icon="ballot"
      style="width: 800px"
      headerIcon="close"
      hasBottomSlot
      @header-icon-click="$emit('close')"
    >
      <form>
        <b-field label="日付" horizontal>
          <ValidationField rules="required">
            <Datepicker v-model="holidayForm.targetDate" />
          </ValidationField>
        </b-field>
        <b-field label="種別" horizontal>
          <div class="block">
            <b-radio
              name="type"
              v-model="holidayForm.officialFlag"
              :native-value="true"
              >祝日</b-radio
            >
            <b-radio
              name="type"
              v-model="holidayForm.officialFlag"
              :native-false="true"
              >休館日</b-radio
            >
          </div>
        </b-field>
        <b-field label="名称" horizontal>
          <ValidationInput
            v-model="holidayForm.name"
            rules="required|max:10"
          ></ValidationInput>
        </b-field>
        <b-field label="エリア" class="has-check" horizontal>
          <ValidationField rules="required">
            <b-checkbox
              v-model="holidayForm.areas"
              v-for="option in AREA_OPTIONS"
              :key="option.value"
              :native-value="option.value"
            >
              {{ option.label }}
            </b-checkbox>
          </ValidationField>
        </b-field>
      </form>
      <div slot="bottom" class="level p-4">
        <div class="level-left">
          <div class="level-item buttons">
            <b-button
              native-type="button"
              type="is-primary"
              :loading="isLoading"
              @click.prevent="handleSubmit(willSave)"
              >登録</b-button
            >
            <b-button
              type="is-light"
              @click="$emit('close')"
              :loading="isLoading"
              >キャンセル</b-button
            >
          </div>
        </div>
        <div class="level-right" v-if="!isCreate">
          <div class="level-item buttons">
            <b-button
              native-type="button"
              type="is-danger"
              icon-right="delete"
              :loading="isLoading"
              @click.prevent="willDelete"
            />
          </div>
        </div>
      </div>
    </CardComponent>
  </ValidationObserver>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { MHoliday, HolidayForm } from '@/types/typeHoliday'
import { Enums } from '@/types/enum'
import { ApiHoliday } from '@/api'
import { mapState } from 'vuex'
export default {
  mixins: [BaseMixin],
  components: { CardComponent },
  props: {
    mHoliday: {
      type: MHoliday,
      default: null
    }
  },
  computed: {
    AREA_OPTIONS: () => Enums.Area.list,
    ...mapState('storeAuth', ['authHoliday']),
    cardTitle () {
      return this.mInstructor ? '祝日・休館日編集' : '祝日・休館日新規作成'
    },
    isCreate () {
      return !this.mHoliday
    },
  },
  data () {
    return {
      holidayForm: new HolidayForm()
    }
  },
  methods: {
    willSave () {
      this.isLoading = true
      setTimeout(() => {
        ApiHoliday.saveHoliday(this.holidayForm).then(({ mHoliday }) => {
          this.$emit('saved', mHoliday)
          this.$emit('close')
          // this.isLoading = false
        }).catch(this.handleError)
      }, 500)
    },
    willDelete () {
      console.log('willDelete')
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        confirmText: '削除する',
        cancelText: 'キャンセル',
        message: '祝日・休館日を削除しますか？',
        onConfirm: () => {
          this.isLoading = true
          setTimeout(() => {
            ApiHoliday.deleteHoliday(this.mHoliday.id).then(() => {
              this.$emit('deleted', this.mHoliday.id)
              this.$emit('close')
              // this.isLoading = false
            }).catch(this.handleError)
          }, 500)
        }
      })
    }
  },
  mounted () {
    if (this.mHoliday) {
      this.holidayForm.fill(this.mHoliday)
    }
  }
}
</script>
