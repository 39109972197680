<template>
  <b-table
    :loading="isLoading"
    :paginated="paginated"
    :per-page="perPage"
    :striped="true"
    narrowed
    :default-sort="defaultSort"
    default-sort-direction="desc"
    :data="rows"
  >
    <b-table-column
      label="日付"
      width="15em"
      field="targetDateSortVal"
      sortable
      v-slot="{ row }"
    >
      {{ row.targetDate.format('YYYY/MM/DD (dd)') }}
    </b-table-column>
    <b-table-column label="名称" width="18em" v-slot="{ row }">
      {{ row.name }}
    </b-table-column>
    <b-table-column label="種別" width="6em" v-slot="{ row }">
      {{ row.typeLabel }}
    </b-table-column>
    <b-table-column
       v-for="area in areas"
       :key="area.value"
      :label="area.label"
      width="8em"
      v-slot="{ row }"
    >
      <b-icon icon="check" v-if="row.hasArea(area.value)" />
      <small v-else></small>
    </b-table-column>
    <b-table-column
      custom-key="actions"
      cell-class="is-actions-cell"
      v-slot="{ row }"
    >
      <div class="buttons is-right">
        <b-button
          type="is-warning"
          size="is-small"
          icon-left="square-edit-outline"
          @click="$emit('willEdit', row)"
        />
      </div>
    </b-table-column>

    <section class="section" slot="empty">
      <div class="content has-text-grey has-text-centered">
        <template v-if="isLoading">
          <p>
            <b-icon icon="dots-horizontal" size="is-large" />
          </p>
          <p>Fetching data...</p>
        </template>
        <template v-else>
          <p>
            <b-icon icon="emoticon-sad" size="is-large" />
          </p>
          <p>Nothing's here&hellip;</p>
        </template>
      </div>
    </section>
  </b-table>
</template>

<script>
import { HolidayTableFilter } from '@/types/typeHoliday'
import { Enums } from '@/types/enum'

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    mHolidays: {
      type: Array,
      default: () => [],
    },
    tableFilter: {
      type: HolidayTableFilter,
      default: null
    },
  },
  data () {
    return {
      perPage: 25,
      defaultSort: 'targetDateSortVal'
    }
  },
  computed: {
    areas: () => Enums.Area.list,
    paginated () {
      return this.rows.length > this.perPage
    },
    rows () {
      if (!this.tableFilter) {
        return this.mHolidays
      }
      return this.mHolidays.filter(row => this.tableFilter.test(row))
    },
  },
}
</script>
