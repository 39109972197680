<template>
  <div>
    <TitleBar :title-stack="titleStack" />
    <HeroBar>
      祝日・休館日管理
      <div class="buttons" slot="right">
        <b-button
          icon-left="plus-circle"
          type="is-info"
          size="is-small"
          @click="willCreate"
          >新規作成</b-button
        >
      </div>
    </HeroBar>
    <section class="section is-main-section">
      <div class="columns">
        <div
          class="
            column
            is-two-thirds-fullhd is-four-fifths-widescreen is-full-desktop
          "
        >
          <CardComponent
            class="has-table has-mobile-sort-spaced"
            title="祝日・休館日一覧"
            icon="lock"
          >
            <div class="notification is-card-toolbar">
              <div class="level">
                <div class="level-left">
                  <div class="level-item">
                    <div class="block">
                      <b-field label="エリア" class="has-check">
                        <EnumPicker
                          :members="AREA_OPTIONS"
                          noneLabel="全て"
                          :grouped="true"
                          :grouped-multiline="false"
                          v-model="tableFilter.area"
                        />
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <b-field grouped>
                      <b-field label="年指定">
                        <b-select v-model="tableFilter.year">
                          <option :value="null" key="none">全て</option>
                          <option
                            v-for="(label, val) in YEAR_OPTIONS"
                            :value="val"
                            :key="val"
                          >
                            {{ label }}
                          </option>
                        </b-select>
                      </b-field>
                      <b-field label="種別">
                        <b-checkbox v-model="tableFilter.showOfficial"
                          >祝日</b-checkbox
                        >
                        <b-checkbox v-model="tableFilter.showSome"
                          >休館日</b-checkbox
                        >
                      </b-field>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <HolidayTable
              :isLoading="isLoading"
              :mHolidays="mHolidays"
              :tableFilter="tableFilter"
              @willEdit="willEdit"
            />
          </CardComponent>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import EnumPicker from '@/components/EnumPicker.vue'
import CardComponent from '@/components/CardComponent.vue'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import { ApiHoliday } from '@/api'
import HolidayTable from '@/components/Holidays/HolidayTable.vue'
import { HolidayTableFilter } from '@/types/typeHoliday'
import { Enums } from '@/types/enum'
import HolidayFormVue from '@/components/Holidays/HolidayForm.vue'
import { Collection } from '@/util'
import { mapMutations } from 'vuex'
export default {
  mixins: [BaseMixin],
  components: { EnumPicker, HeroBar, TitleBar, CardComponent, HolidayTable },
  computed: {
    AREA_OPTIONS: () => Enums.Area.list,
    titleStack () {
      return [
        'マスタ管理', '祝日・休館日'
      ]
    },
    YEAR_OPTIONS () {
      return this.mHolidays.reduce((map, mHoliday) => {
        map[mHoliday.year] = `${mHoliday.year}年`
        return map
      }, {})
    }
  },
  data () {
    return {
      mHolidays: [],
      tableFilter: new HolidayTableFilter(),
    }
  },
  methods: {
    ...mapMutations('storeHoliday', {
      resetStoreDataState: 'resetDataState'
    }),
    willCreate () {
      console.log('willCreate')
      this.$buefy.modal.open({
        parent: this,
        component: HolidayFormVue,
        hasModalCard: true,
        canCancel: false,
        // trapFocus: true,
        events: {
          saved: this.didSaved
        }
      })
    },
    willEdit (mHoliday) {
      console.log(mHoliday, 'willEdit')
      this.$buefy.modal.open({
        parent: this,
        component: HolidayFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { mHoliday },
        events: {
          saved: this.didSaved,
          deleted: (deletedId) => {
            this.mHolidays = new Collection(this.mHolidays).remove(deletedId).members
            this.alertSuccess('祝日・休館日情報を削除しました')
            this.resetStoreDataState()
          }
        }
      })
    },
    didSaved (mHoliday) {
      console.log(mHoliday, 'didSaved')
      this.mHolidays = new Collection(this.mHolidays).sort('targetDate', 'asc').set(mHoliday).members
      this.alertSuccess('祝日・休館日情報を更新しました')
      this.resetStoreDataState()
    },
  },
  mounted () {
    this.isLoading = true
    setTimeout(() => {
      ApiHoliday.fetchHolidays().then(({ mHolidays }) => {
        this.mHolidays = new Collection(mHolidays).sort('targetDate', 'asc').members
        this.isLoading = false
      })
    }, 300)
  }
}
</script>
